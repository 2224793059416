<template>
    <div class="widget widget-container">
        <template v-if="!widgetRef.instanceProperties.blocks?.length && silent == false">
            <div class="widget-filler">
                <div
                    v-if="isEditMode"
                    class="widget-filler-zone"
                    @drop="onFillerDrop($event)"
                    @dragover="onFillerDragover($event)"
                    @dragenter="onFillerDragenter($event)"
                    @dragleave="onFillerDragleave($event)"
                >
                    drop widgets here
                </div>
            </div>
        </template>
        <template v-for="(block, blockIndex) in widgetRef.instanceProperties.blocks" :key="block">
            <div
                v-if="isEditMode"
                class="widget-block-zone"
                :class="{ hovering: isHoveringBlock == blockIndex }"
                @drop="onBlockDrop($event, blockIndex)"
                @dragover="onBlockDragover($event, blockIndex)"
                @dragenter="onBlockDragenter($event, blockIndex)"
                @dragleave="onBlockDragleave($event)"
            ></div>
            <div class="widget-block">
                <template v-for="(inline, inlineIndex) in block.inlines" :key="inline">
                    <div
                        v-if="isEditMode"
                        class="widget-inline-zone"
                        :class="{ hovering: isHoveringInline.block == blockIndex && isHoveringInline.inline == inlineIndex }"
                        @drop="onInlineDrop($event, blockIndex, inlineIndex)"
                        @dragover="onInlineDragover($event, blockIndex, inlineIndex)"
                        @dragenter="onInlineDragenter($event, blockIndex, inlineIndex)"
                        @dragleave="onInlineDragleave($event)"
                    ></div>
                    <div class="widget-inline mb-2" :class="{ 'p-2': mode == 'edit', hidden: mode != 'edit' && !isVisible(inline) }">
                        <component
                            :is="formEditorStore.componentFromWidgetClassName(inline.classProperties.name)"
                            :mode="mode"
                            :class="{
                                'edit-mode': mode == 'edit',
                                'widget-selected':
                                    widgetRef.instanceProperties.blocks[blockIndex].inlines[inlineIndex].runtimeProperties.selected,
                            }"
                            class="widget"
                            @update:widget="handleWidgetValueUpdate($event)"
                            v-model:widget="widgetRef.instanceProperties.blocks[blockIndex].inlines[inlineIndex]"
                            @click="
                                mode == 'edit' &&
                                    onWidgetClick(
                                        $event,
                                        widgetRef.instanceProperties.blocks[blockIndex].inlines[inlineIndex].instanceProperties.id
                                    )
                            "
                            :zreadonly="isReadonly(inline)"
                            :disabled="isDisabled(inline)"
                        >
                        </component>
                        <div
                            v-if="hasError(widgetRef.instanceProperties.blocks[blockIndex].inlines[inlineIndex].instanceProperties.id)"
                            class="widget-errors"
                        >
                            {{ getError(widgetRef.instanceProperties.blocks[blockIndex].inlines[inlineIndex].instanceProperties.id) }}
                        </div>
                        <div
                            class="widget-actions"
                            v-if="
                                isEditMode &&
                                widgetRef.instanceProperties.blocks[blockIndex].inlines[inlineIndex].runtimeProperties.selected
                            "
                        >
                            <img src="/assets/icons/delete.svg" class="icon-normal" @click="removeWidget(blockIndex, inlineIndex)" />
                        </div>
                    </div>
                    <div
                        v-if="isEditMode && inlineIndex == block.inlines.length - 1"
                        class="widget-inline-zone"
                        :class="{
                            hovering: isHoveringInline.block == blockIndex && isHoveringInline.inline == block.inlines.length,
                        }"
                        @drop="onInlineDrop($event, blockIndex, 'last')"
                        @dragover="onInlineDragover($event, blockIndex, inlineIndex)"
                        @dragenter="isEditMode && onInlineDragenter($event, blockIndex, block.inlines.length)"
                        @dragleave="isEditMode && onInlineDragleave($event)"
                    ></div>
                </template>
            </div>
            <div
                v-if="isEditMode && blockIndex == widgetRef.instanceProperties.blocks.length - 1 && silent == false"
                class="widget-block-zone"
                :class="{ hovering: isHoveringBlock == widgetRef.instanceProperties.blocks.length }"
                @drop="onBlockDrop($event, 'last')"
                @dragover="onBlockDragover($event, blockIndex)"
                @dragenter="isEditMode && onBlockDragenter($event, widgetRef.instanceProperties.blocks.length)"
                @dragleave="isEditMode && onBlockDragleave($event)"
            ></div>
        </template>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';

import RulesService from '../../../services/RulesService';

import { useFormEditorStore } from '../../../stores/formEditorStore';
import { useSelectable } from '../../../composables/useSelectable';
import { useLoggerStore } from '../../../stores/loggerStore';
import { useDragAndDropStore } from '../../../stores/dragAndDropStore';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    stone: {
        type: [Object, Number],
        default: null,
    },
    section: {
        type: Object,
        default: null,
    },
    widget: {
        type: Object,
        required: true,
    },
    silent: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:widget', 'update:widget-value']);

const formEditorStore = useFormEditorStore();
const dragAndDropStore = useDragAndDropStore();
const { logger } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit);
const isHoveringBlock = ref(-1);
const isHoveringInline = ref({
    block: -1,
    inline: -1,
});

logger.dir('props', 'WIDGET VERTICAL LAYOUT (props): ', props);

const isEditMode = computed(() => {
    return props.mode == 'edit';
});

const hasError = computed(() => {
    return (objId) => {
        if (props.stone && props.section) {
            const a =
                formEditorStore.validationErrors.stones.includes(props.stone.id) &&
                formEditorStore.validationErrors.sectionsByStone[props.stone.id].includes(props.section.id) &&
                formEditorStore.validationErrors.fieldsBySectionByStone[props.stone.id][props.section.id][objId];
            //console.log('ERROR IN FIELD', a);
            return a;
        } else {
            return false;
        }
    };
});

const getError = computed(() => {
    return (objId) => {
        if (props.stone && props.section) {
            return formEditorStore.validationErrors.fieldsBySectionByStone[props.stone.id][props.section.id][objId];
        } else {
            return null;
        }
    };
});

const onFillerDragover = (event) => {
    event.preventDefault();
    event.stopPropagation();
};
const onBlockDragover = (event) => {
    event.preventDefault();
    event.stopPropagation();
};
const onInlineDragover = (event) => {
    event.preventDefault();
    event.stopPropagation();
};

const onFillerDragenter = (event) => {
    if (event.target.classList.contains('widget-filler-zone')) {
        event.target.classList.add('hovering');
        event.stopPropagation();
    }
};
const onFillerDragleave = (event) => {
    if (event.target.classList.contains('widget-filler-zone')) {
        event.target.classList.remove('hovering');
        event.stopPropagation();
    }
};

const onBlockDragenter = (event, blockIndex) => {
    if (event.target.classList.contains('widget-block-zone')) {
        // event.target.classList.add('hovering');
        isHoveringBlock.value = blockIndex;
        event.stopPropagation();
    }
};
const onBlockDragleave = (event) => {
    if (event.target.classList.contains('widget-block-zone')) {
        // event.target.classList.remove('hovering');
        isHoveringBlock.value = -1;
        event.stopPropagation();
    }
};

const onInlineDragenter = (event, blockIndex, inlineIndex) => {
    if (event.target.classList.contains('widget-inline-zone')) {
        // event.target.classList.add('hovering');
        isHoveringInline.value.block = blockIndex;
        isHoveringInline.value.inline = inlineIndex;
        event.stopPropagation();
    }
};
const onInlineDragleave = (event) => {
    if (event.target.classList.contains('widget-inline-zone')) {
        // event.target.classList.remove('hovering');
        isHoveringInline.value.block = -1;
        isHoveringInline.value.inline = -1;
        event.stopPropagation();
    }
};

const onFillerDrop = (event) => {
    const data = JSON.parse(event.dataTransfer.getData(event.dataTransfer.types));
    formEditorStore.appendWidget(widgetRef.value, data);

    console.log('FILLER DROP', data);
    event.stopPropagation();
};
const onBlockDrop = (event, blockIndex) => {
    const data = JSON.parse(event.dataTransfer.getData(event.dataTransfer.types));
    if (blockIndex == 'last') {
        formEditorStore.appendWidget(widgetRef.value, data);
    } else {
        formEditorStore.insertWidgetBefore(widgetRef.value, data, blockIndex);
    }

    console.log('BLOCK DROP', data);
    event.stopPropagation();
};
const onInlineDrop = (event, blockIndex, inlineIndex) => {
    const data = JSON.parse(event.dataTransfer.getData(event.dataTransfer.types));
    if (inlineIndex == 'last') {
        formEditorStore.appendWidgetInline(widgetRef.value, data, blockIndex);
    } else {
        formEditorStore.insertWidgetInlineBefore(widgetRef.value, data, blockIndex, inlineIndex);
    }

    console.log('INLINE DROP', data);
    event.stopPropagation();
};

// WATCH PER CAPIRE QUANDO UN DRAG&DROP E' TERMINATO (ANCHE FUORI DALL'AREA DROPPABLE)
watch(
    () => dragAndDropStore.dragging,
    (newValue) => {
        if (!newValue) {
            isHoveringBlock.value = -1;
            isHoveringInline.value.block = -1;
            isHoveringInline.value.inline = -1;
        }
    }
);

// const onClick = (event, id) => {
//     const { handleClick } = useSelectable(id);
//     handleClick(event);
//     event.stopPropagation();
// };
const onWidgetClick = (event, id) => {
    console.log('CLICK');
    const { handleClick } = useSelectable(formEditorStore, id);
    handleClick(event);
    event.stopPropagation();
};

const handleWidgetValueUpdate = (event) => {
    // console.log('handleWidgetValueUpdate', event);
    emit('update:widget-value', event);
};

// const selected = computed(() => {
//     return widgetRef.value.runtimeProperties.selected;
// });

const removeWidget = (blockIndex, inlineIndex) => {
    formEditorStore.removeWidget(widgetRef.value, blockIndex, inlineIndex);
};

const isVisible = computed(() => {
    return (widget) => {
        const r = RulesService.isVisible(widget);
        // console.log('CONTAINER, ISVIS', widget.instanceProperties.name, r);
        return r;
    };
});
const isDisabled = computed(() => {
    return (widget) => {
        const r = RulesService.isDisabled(widget);
        // console.log('CONTAINER, ISVIS', widget.instanceProperties.name, r);
        return r;
    };
});
const isReadonly = computed(() => {
    return (widget) => {
        const r = RulesService.isReadonly(widget);
        // console.log('CONTAINER, ISVIS', widget.instanceProperties.name, r);
        return r;
    };
});
</script>

<style lang="postcss" scoped>
.widget-filler {
    @apply p-2;
}

.widget-inline {
    @apply relative;
}
.widget-actions {
    @apply absolute top-2 right-2 bg-white;
}
.widget-filler-zone {
    @apply w-full bg-transparent;

    .env-mode-devel & {
        @apply bg-stone-400;
    }
    .dragging &.hovering {
        @apply bg-transparent h-8;
        @apply border-2 border-dashed border-sky-700;
    }
}
.widget-block-zone {
    @apply w-full h-2 bg-transparent;

    .env-mode-devel & {
        @apply bg-stone-400;
    }
    .dragging &.hovering {
        @apply bg-transparent h-8;
        @apply border-2 border-dashed border-sky-700;
    }
}
.widget-inline-zone {
    @apply w-2 bg-transparent flex-shrink-0 items-stretch;

    .env-mode-devel & {
        @apply bg-stone-400;
    }

    .dragging &.hovering {
        @apply bg-transparent w-16 flex-grow;
        @apply border-2 border-dashed border-sky-700;
    }
}
.widget-block {
    @apply w-full;
    @apply flex;

    & > *:not(.widget-inline-zone) {
        @apply flex-grow flex-shrink basis-full;
    }
}
.widget.edit-mode {
    @apply border-2 border-solid border-transparent;
    &:hover {
        @apply border-sky-500;
    }
}
.widget.edit-mode.widget-selected {
    @apply border-2 border-solid border-sky-500;
    &:hover {
        @apply border-sky-950;
    }
}
.widget-errors {
    @apply text-xs text-red-700;
}
</style>
